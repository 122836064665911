@import url("http://fonts.cdnfonts.com/css/maximum-impact");

#scroll-container {
  scroll-snap-type: y mandatory;
  overflow: auto;
}

.scroll-child {
  scroll-snap-align: start; /* or end or center */
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-height: -moz-available; /* WebKit-based browsers will ignore this. */
  min-height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  min-height: stretch;
}

body {
  background-color: rgb(120, 141, 171);
  height: 100%;
}

nav {
  background-color: #030340;
  padding: 1em;
  color: white;
  display: flex;
  justify-content: space-between;
  height: 3em;
}

@keyframes float {
  0% {
    transform: translateY(0em);
  }
  50% {
    transform: translateY(0.1em);
  }
  100% {
    transform: translateY(0em);
  }
}

.scroll-arrow {
  animation: 2s ease-in-out infinite float;
}
